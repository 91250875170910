export const BUDGET = {
  NEW_CAR_TYPE: '0Km',
  USED_CAR_TYPE: 'Usados',
  CREDIT_LINE_SORT: {
    MAX_CAPITAL_DESC: 0,
    TNA_ASC: 1,
    QUOTE_QUANTITY_ASC: 2
  },
  CREDIT_LINE_CREATE_MODE: 'agregar',
  CREDIT_LINE_EDIT_MODE: 'editar',
};

export const LEAD_ORIGIN_TYPES = {
    COTIZADOR: 'Cotizador',
    CARMUV: 'Carmuv',
    WEB: 'Formulario Web'
}

export const EMAIL_TYPE = {
  RESERVATION:'RESERVATION',
  DETAIL:'DETAIL',
  USED_CAR: 'USED_CAR',
};

export const STOCK_STATES = {
  RESERVED: 'Reservado',
};

export const ID_ARG = 'be64bdb9-9afb-11ea-a665-0242ac120004'
export const ARGENTINA = 'Argentina'

export const MENU_BUTTON_ID = {
  'Comprá': 'home-menu-btn-compra',
  'Vendé': 'home-menu-btn-vende',
  'Cotizador': 'home-menu-btn-cotizador',
  'Catálogo': 'home-menu-btn-catalogo',
  'Blog': 'home-menu-btn-blog',
};
